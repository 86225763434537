import { Fragment, useEffect } from 'react';
import {
  Consulterdoor,
  PlugseekDark,
  PlugseekWhite,
  Nawinne,
} from '../../assets/images';
import { Footer } from '../../components/footer';
import { Header } from '../../components/header';
import WOW from 'wowjs';
import { SEODecorator } from '../../shared/SEODecorator';

const Portfolio = () => {
  useEffect(() => {
    new WOW.WOW({
      mobile: false,
      live: false,
    }).init();
  }, []);

  return (
    <Fragment>
      <SEODecorator
        title={'Phrasecode | Explore Our Success Stories'}
        description={
          'Explore our portfolio of cutting-edge technology projects! As a leading software company, Phrasecode is committed to providing quality services to help businesses succeed. Contact us now to learn more about our services and how we can help your business succeed with innovative technology solutions.'
        }
        imageUrl={'https://phrasecode.com/public/Innovation-amico.png'}
        imageAlt={'Phrasecode | Remote Software Engineering Service'}
        type={'website'}
      />
      <div className=''>
        <Header />

        <section className='ud-pt-24'>
          <div className='ud-px-4 2xl:ud-container'>
            <div
              className='wow fadeInDown fadeOutDown ud-relative ud-mx-auto ud-mb-12 ud-max-w-[620px] ud-pt-6 ud-text-center lg:ud-mb-20 lg:ud-pt-16'
              data-wow-delay='.2s'
            >
              <span className='title'> PORTFOLIO </span>
              <h2 className='ud-mb-5 ud-font-heading ud-text-3xl ud-font-semibold ud-text-dark dark:ud-text-white sm:ud-text-4xl md:ud-text-[50px] md:ud-leading-[60px]'>
                Explore Our Latest Case Studies
              </h2>
              <p className='ud-text-base ud-text-dark-text'>
                We take great satisfaction in providing our customers with
                innovative, state-of-the-art solutions with cutting-edge
                technologies.
              </p>
            </div>
            <div className='ud-relative ud-z-10 ud-overflow-hidden ud-rounded ud-px-8 ud-pt-0 ud-pb-8 md:ud-px-[70px] md:ud-pb-[70px] lg:ud-px-[60px] lg:ud-pb-[60px] xl:ud-px-[70px] xl:ud-pb-[70px]'>
              <div className='ud-w-full'>
                <div
                  className='wow fadeInUp gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6'
                  data-wow-delay='.3s'
                >
                  <img
                    className='w-full ud-order-last'
                    src={Nawinne}
                    alt='Nawinne Dashboard'
                  />
                  <div className='mt-4 ud-order-first'>
                    <h2 className='mb-4 text-4xl tracking-tight font-extrabold ud-text-dark dark:ud-text-white'>
                      Nawinne - Hospital Management System
                    </h2>
                    <p className='mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400'>
                      <p className=''>
                        The Nawinna Hospital Management System is a robust
                        platform by integrating,
                      </p>
                      <ul className='ml-6 font-bold'>
                        <li className=''>Inventory Management</li>
                        <li className=''>Supplier Management</li>
                        <li className=''>Employee Management</li>
                        <li className=''>Finance management modules.</li>
                      </ul>
                      <p className='margin-top: 0; padding-top: 0;'>
                        This comprehensive system streamlines the
                        hospital&apos;s day-to-day operations, allowing
                        efficient tracking and management of inventory levels,
                        ensuring timely supply chain management with suppliers,
                        optimizing staff scheduling and performance, and
                        handling financial aspects seamlessly.
                      </p>
                    </p>
                  </div>
                </div>
                <div
                  className='wow fadeInUp gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6'
                  data-wow-delay='.3s'
                >
                  <img
                    className='w-full dark:ud-hidden'
                    src={PlugseekWhite}
                    alt='Plugseek Dashboard White'
                  />
                  <img
                    className='w-full hidden dark:ud-block'
                    src={PlugseekDark}
                    alt='Plugseek Dashboard Dark'
                  />
                  <div className='mt-4 md:mt-0'>
                    <h2 className='mb-4 text-4xl tracking-tight font-extrabold ud-text-dark dark:ud-text-white'>
                      Plugseek - A New Era Of Traveling
                    </h2>
                    <p className='mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400'>
                      Do you own an electric vehicle? Worried About Covering
                      Long Distances in Sri Lanka with Your EV? Plugseek is
                      prepared to simplify your travel experience. It is no
                      longer necessary to stress out over Sri Lanka's long
                      distances. Simply said, Plugseek will show you where the
                      closest electric vehicle charging outlet is in Sri Lanka.
                    </p>
                    <div
                      className='wow fadeInUp ud-flex ud-flex-wrap ud-items-center ud-justify-center md:ud-justify-start'
                      data-wow-delay='.5s'
                    >
                      <a
                        href='https://www.plugseek.com/'
                        target='_blank'
                        rel='noreferrer'
                        className='ud-inline-flex ud-items-center ud-rounded ud-bg-primary ud-py-[10px] ud-px-6 ud-font-heading ud-text-base ud-text-white hover:ud-bg-opacity-90 md:ud-py-[14px] md:ud-px-8'
                      >
                        See Product
                        <span className='ud-pl-3'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.172 7L6.808 1.636L8.222 0.222L16 8L8.222 15.778L6.808 14.364L12.172 9H0V7H12.172Z'
                              fill='white'
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className='wow fadeInUp gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6'
                  data-wow-delay='.3s'
                >
                  <img
                    className='w-full ud-order-last'
                    src={Consulterdoor}
                    alt='Consulterdoor Dashboard'
                  />
                  <div className='mt-4 ud-order-first'>
                    <h2 className='mb-4 text-4xl tracking-tight font-extrabold ud-text-dark dark:ud-text-white'>
                      Consulterdoor
                    </h2>
                    <p className='mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400'>
                      Do you need a consultation? Don’t you have much time to go
                      and meet consultants? Here is the solution for you.
                      Consulterdoor has well experienced professional
                      consultants in a variety of fields. Our platform makes it
                      easy to find the right consultant for your needs and
                      schedule an online meeting to get personalized advice.
                    </p>
                    <div
                      className='wow fadeInUp ud-flex ud-flex-wrap ud-items-center ud-justify-center md:ud-justify-start'
                      data-wow-delay='.5s'
                    >
                      <a
                        href='http://consulterdoor.com/'
                        target='_blank'
                        rel='noreferrer'
                        className='ud-inline-flex ud-items-center ud-rounded ud-bg-primary ud-py-[10px] ud-px-6 ud-font-heading ud-text-base ud-text-white hover:ud-bg-opacity-90 md:ud-py-[14px] md:ud-px-8'
                      >
                        See Product
                        <span className='ud-pl-3'>
                          <svg
                            width={16}
                            height={16}
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M12.172 7L6.808 1.636L8.222 0.222L16 8L8.222 15.778L6.808 14.364L12.172 9H0V7H12.172Z'
                              fill='white'
                            />
                          </svg>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Portfolio;
