import { formatString } from "../../shared";
import { Tooltip } from "../Tooltip";

const BlogPost = ({blog}) => {
  return (
      <a className='wow fadeInUp max-w-sm ud-blog-background rounded-lg shadow-md p-2 ud-cursor-pointer' href={`./blogs/${blog.id}`}>
        <div className='mb-2'>
          <img
            className='rounded-t-lg ud-bg-cover'
            src={blog.imageUrl}
            alt={blog.title}
          />
        </div>
        <div className='md:p-5 lg:p-5 ud-text-left'>
          <div>
            <Tooltip text={blog.title}>
            <h5 className='ud-blog-post-text tracking-tight ud-font-heading ud-text-dark pb-2' data-tooltip-id="my-tooltip">
            {formatString(blog.title,{maxLength:65,appendEnd:' ...'})}
            </h5>
            </Tooltip>
          </div>
          <div className='mb-3 font-normal text-gray-700 dark:text-gray-400 flex justify-between'>
            <p className=''>{blog.author}</p>
            <p>{blog.readTime}</p>
          </div>
        </div>
      </a>
  );
};


export { BlogPost };
