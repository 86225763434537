import { ApiClient } from './apiClient';

export const submitGetInTouchApi = async (request) => {
  await ApiClient.post(`/`, request);
  return null;
};

export const getBlogs = async (limit) => {
  const data = await ApiClient.get(`/blog/list/${limit}`);
  return data.data;
};

export const getBlog = async (title) => {
  const data = await ApiClient.get(`/blog/${title}`);
  return data.data;
};

export const getAllBlogIds = async () => {
  const data = await ApiClient.get(`/blog/list/ids`);
  return data.data;
};
