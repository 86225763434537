import { Router } from './routes';
import './App.css';
import ReactGA from 'react-ga4';
import { Config } from './config';

ReactGA.initialize(Config.TRACKING_CODE)

function App() {
  return (
    <div className='App'>
      <Router />
    </div>
  );
}

export default App;
