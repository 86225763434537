import { BlogPost } from '../blogPost';

const BlogList = ({ blogs }) => {
  return (
    <div className='container my-12 mx-auto px-4 md:px-12'>
      <div className='flex flex-wrap -mx-1 lg:-mx-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-8 xs:gap-4'>
          {blogs &&
            blogs.map((blog, index) => <BlogPost blog={blog} key={index} />)}
        </div>
      </div>
    </div>
  );
};

export { BlogList };
