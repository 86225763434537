import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom';

import Home from '../pages/Home';
import Blogs from '../pages/Blogs';
import BlogView from '../pages/BlogView';
import NotFound from '../pages/NotFound';
import Portfolio from '../pages/Portfolio';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Disclaimer from '../pages/Disclaimer';

const Router = () => {
  return (
    <BrowserRouter>
      <div className='w-full min-h-screen bg-brand-gray sm:py-6'>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/portfolio' element={<Portfolio />} />
          <Route exact path='/blogs' element={<Blogs />} />
          <Route exact path='/blogs/:id' element={<BlogView />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route exact path='/disclaimer' element={<Disclaimer />} />
          <Route exact={true} path='*' element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export { Router };
