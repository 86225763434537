import { useEffect } from 'react';
import {
  InnovationAmico,
  PlugseekDark,
  PlugseekWhite,
  Nawinne,
} from '../../assets/images';
import { About } from './about';
import WOW from 'wowjs';
import { useTypewriter } from 'react-simple-typewriter';
import { Blogs } from './blogs';
import { Header } from '../../components/header';
import { submitGetInTouchApi } from '../../services';
import { Footer } from '../../components/footer';
import { SEODecorator } from '../../shared/SEODecorator';

function Home() {
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  const handleScroll = (event) => {
    // show or hide the back-top-top button
    const backToTop = document.querySelector('.back-to-top');
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      backToTop.style.display = 'flex';
    } else {
      backToTop.style.display = 'none';
    }
  };
  useEffect(() => {
    new WOW.WOW({
      mobile: false,
      live: false,
    }).init();
  }, []);

  const [typingText] = useTypewriter({
    words: [
      'Revolutionize',
      'Transform',
      'Accelerate',
      'Empower',
      'Elevate',
      'Fuel Up',
    ],
    loop: 0,
  });

  const backToTopFunc = (to = 0, duration = 500) => {
    const element = document.documentElement;
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };
    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };

  async function submitGetInTouch() {
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const phone = document.getElementById('phone').value;
    const subject = document.getElementById('subject').value;
    const message = document.getElementById('message').value;
    const requestBody = {
      name,
      email,
      phone,
      subject,
      message,
    };
    if (!email && !phone) {
      document.getElementById('supportCheckbox').innerHTML =
        'Please add email or phone to contact you';
      return;
    } else {
      document.getElementById('supportCheckbox').innerHTML = '';
    }
    document.getElementById('loadingOverlay').style.display = 'flex';
    await submitGetInTouchApi(requestBody);
    document.getElementById('loadingOverlay').style.display = 'none';
  }

  return (
    <div className='App'>
      <SEODecorator
        title={'Phrasecode | Remote Software Engineering Service'}
        description={
          'Discover how Phrasecode, the software company that provides cutting-edge technologies and top-notch services, can help your startup succeed. Learn more now and unleash your full potential!'
        }
        imageUrl={'https://phrasecode.com/public/Innovation-amico.png'}
        imageAlt={'Phrasecode | Remote Software Engineering Service'}
        type={'website'}
      />
      <div>
        {/* ===== Header Start ===== */}
        <Header />
        {/* ===== Header End ===== */}
        {/* ===== Hero Section Start ===== */}
        <section
          id='home'
          className='hero-background-gradient ud-bg-cover ud-bg-center ud-px-8 ud-pt-28 ud-pb-24 sm:ud-pt-36 lg:ud-pt-[170px] lg:ud-pb-[120px]'
        >
          <div className='ud-px-8 2xl:ud-container'>
            <div className='ud--mx-4 ud-flex ud-flex-wrap ud-items-center'>
              <div className='ud-w-full ud-px-3 lg:ud-w-1/2'>
                <div className='ud-mx-auto ud-mb-12 ud-max-w-[530px] ud-text-center lg:ud-ml-0 lg:ud-mb-0 lg:ud-text-left'>
                  <h1
                    className='wow fadeInUp ud-mb-5 ud-font-heading ud-text-2xl ud-font-semibold dark:ud-text-white sm:ud-text-4xl md:ud-text-[50px] md:ud-leading-[60px]'
                    data-wow-delay='.3s'
                  >
                    Digitally Catering You To
                    <span className='txt-type a3g'> {typingText}</span>
                  </h1>
                  <p
                    className='wow fadeInUp ud-mb-12 ud-text-base ud-text-dark-text'
                    data-wow-delay='.4s'
                  >
                    At Phrasecode, we pride ourselves on delivering top-notch,
                    cutting-edge products to our clients. Our team of
                    experienced engineers and developers have a proven track
                    record of creating innovative solutions that exceed
                    expectations and drive business success.
                  </p>
                  <div
                    className='wow fadeInUp ud-flex ud-flex-wrap ud-items-center ud-justify-center md:ud-justify-start'
                    data-wow-delay='.5s'
                  >
                    <a
                      href='#support'
                      className='ud-inline-flex ud-items-center ud-rounded ud-bg-primary ud-py-[10px] ud-px-6 ud-font-heading ud-text-base ud-text-white hover:ud-bg-opacity-90 md:ud-py-[14px] md:ud-px-8'
                    >
                      Get More
                      <span className='ud-pl-3'>
                        <svg
                          width={16}
                          height={16}
                          viewBox='0 0 16 16'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M12.172 7L6.808 1.636L8.222 0.222L16 8L8.222 15.778L6.808 14.364L12.172 9H0V7H12.172Z'
                            fill='white'
                          />
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className='ud-w-full ud-px-4 lg:ud-w-1/2'>
                <div
                  className='wow fadeInRight ud-hero-image ud-relative ud-z-30 ud-mx-auto ud-w-full ud-max-w-[700px] lg:ud-ml-0'
                  data-wow-delay='.3s'
                >
                  <div className='ud-absolute ud-top-0 ud-right-0'>
                    <img src={InnovationAmico} alt='phrasecode innovation' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ===== Hero Section End ===== */}
        {/* ===== Features Section Start ===== */}
        <section
          id='features'
          className='ud-pt-14 sm:ud-pt-20 lg:ud-pt-[130px]'
        >
          <div className='ud-px-4 2xl:ud-container'>
            {/* Section Title */}
            <div
              className='wow fadeInUp ud-relative ud-mx-auto ud-mb-12 ud-max-w-[620px] ud-pt-6 ud-text-center md:ud-mb-20 lg:ud-pt-16'
              data-wow-delay='.2s'
            >
              <span className='title'> FEATURES </span>
              <h2 className='ud-mb-5 ud-font-heading ud-text-3xl ud-font-semibold ud-text-dark dark:ud-text-white sm:ud-text-4xl md:ud-text-[50px] md:ud-leading-[60px]'>
                Our Unique &amp; Awesome Core Features
              </h2>
              <p className='ud-text-base ud-text-dark-text'>
                Partner with Our Unique &amp; Incredible Services
              </p>
            </div>
            <div className='ud-mx-4 ud-flex ud-flex-wrap ud-justify-center'>
              <div
                className='wow fadeInUp px-2 py-2 md:px-8 lg:px-8 md:py-8 lg:py-8 ud-flex ud-w-full ud-flex-col ud-items-center ud-text-center md:ud-w-1/2 lg:ud-w-1/2'
                data-wow-delay='.3s'
              >
                <div className='p-6 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 ud-service-card-background dark:border-gray-700 ud-flex ud-max-w-xl ud-flex-col ud-items-center ud-text-center dark:ud-bg-[#1D232D]'>
                  <svg
                    version={1.0}
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512.000000 512.000000'
                    preserveAspectRatio='xMidYMid meet'
                    className='ud-service-svg-color ud-service-image'
                  >
                    <g
                      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
                      stroke='none'
                    >
                      <path
                        d='M846 5100 c-99 -39 -160 -129 -159 -234 1 -34 6 -72 11 -86 10 -24
-10 -45 -344 -380 l-354 -356 0 -407 c0 -335 3 -413 14 -435 17 -32 262 -275
293 -291 15 -8 164 -11 479 -11 l458 0 256 -255 257 -255 534 0 535 0 34 -52
c90 -137 265 -157 386 -46 98 91 105 251 16 350 -117 129 -321 111 -408 -37
l-26 -45 -501 0 -502 0 -255 255 -256 255 -469 0 -470 0 -102 102 -103 103 0
350 0 350 329 329 329 329 32 -12 c83 -29 202 1 262 65 71 76 89 198 42 292
-60 120 -193 171 -318 122z m124 -160 c20 0 50 -45 50 -74 -1 -54 -49 -92
-101 -81 -48 11 -73 67 -53 120 8 20 63 46 82 40 8 -3 18 -5 22 -5z m2139
-2395 c65 -33 52 -134 -18 -150 -52 -11 -100 27 -100 80 0 36 17 61 50 74 33
13 36 13 68 -4z'
                      />
                      <path
                        d='M1980 5111 c-67 -21 -136 -78 -162 -137 l-11 -24 -184 0 c-191 0
-232 -8 -247 -45 -3 -9 -6 -71 -6 -138 l0 -121 -159 -160 -160 -160 -34 12
c-118 43 -277 -34 -318 -154 -17 -51 -16 -140 4 -185 37 -89 141 -159 236
-159 136 0 251 115 251 251 0 32 -5 70 -10 85 -11 27 -5 34 164 203 96 97 180
189 186 204 5 16 10 66 10 113 l0 84 133 0 c131 0 134 0 145 -24 49 -109 186
-169 304 -134 l39 12 500 -500 499 -499 0 -117 0 -116 -44 -26 c-90 -52 -132
-135 -124 -239 6 -71 23 -111 69 -156 103 -104 257 -104 359 -1 104 103 103
256 -1 358 -18 18 -45 38 -61 44 l-28 13 0 137 c0 75 -5 149 -10 165 -6 15
-242 260 -526 544 -503 504 -515 516 -504 543 5 15 10 53 10 85 0 105 -68 199
-173 238 -36 13 -110 15 -147 4z m128 -194 c56 -52 -1 -149 -79 -132 -72 16
-83 121 -15 151 36 15 62 10 94 -19z m-1135 -749 c32 -17 46 -39 46 -73 0 -57
-53 -93 -109 -74 -34 12 -50 35 -50 74 0 65 58 103 113 73z m2319 -948 c31
-24 39 -59 22 -99 -21 -51 -92 -59 -131 -15 -31 34 -29 75 3 108 32 31 71 34
106 6z'
                      />
                      <path
                        d='M2750 5111 c-71 -23 -131 -74 -166 -141 -27 -51 -25 -162 3 -216 27
-50 75 -98 122 -120 47 -23 129 -29 176 -14 l38 12 413 -413 414 -414 0 -400
c0 -236 4 -414 10 -435 7 -23 41 -65 100 -125 86 -87 90 -93 80 -119 -19 -51
-12 -138 16 -194 97 -194 376 -183 462 17 19 45 20 134 3 185 -41 120 -187
192 -313 154 l-39 -11 -70 68 -69 69 0 402 c0 221 -3 417 -6 435 -6 28 -83
111 -441 470 l-435 437 12 37 c15 47 9 129 -14 176 -22 47 -70 96 -120 121
-43 22 -136 32 -176 19z m128 -197 c30 -34 28 -70 -7 -105 -34 -34 -68 -37
-104 -9 -31 25 -41 63 -25 98 27 56 93 64 136 16z m1365 -2215 c19 -23 22 -70
7 -98 -15 -29 -61 -44 -95 -31 -37 14 -54 38 -54 75 0 75 94 111 142 54z'
                      />
                      <path
                        d='M3507 5106 c-82 -30 -141 -91 -167 -170 -15 -48 -8 -141 14 -184 21
-40 77 -97 119 -119 45 -24 169 -24 218 0 46 23 103 80 119 118 l12 29 207 0
206 0 272 -273 273 -272 0 -162 0 -163 -37 -19 c-187 -96 -170 -376 26 -458
93 -39 220 -8 287 71 104 121 75 302 -61 382 l-45 26 0 185 c0 115 -4 193 -11
206 -16 31 -598 615 -631 633 -21 11 -76 14 -257 14 -225 0 -230 0 -236 21
-11 33 -75 98 -119 121 -51 26 -139 33 -189 14z m138 -186 c30 -30 26 -87 -9
-116 -37 -32 -71 -30 -107 5 -33 33 -37 65 -13 99 31 45 89 50 129 12z m1267
-1184 c28 -21 39 -61 27 -96 -26 -73 -137 -66 -154 9 -7 31 15 85 37 94 25 10
73 6 90 -7z'
                      />
                      <path
                        d='M1624 4337 c-77 -30 -132 -87 -160 -164 -40 -113 20 -248 136 -309
49 -26 161 -25 214 0 45 23 100 77 118 117 l13 29 120 0 120 0 143 -143 142
-142 0 -251 0 -252 -30 -12 c-37 -16 -97 -81 -117 -128 -25 -60 -20 -145 14
-210 95 -186 351 -186 446 0 34 65 39 150 14 210 -20 47 -80 112 -117 128
l-30 12 0 257 c0 141 -5 272 -10 291 -7 26 -57 83 -198 223 l-188 187 -150 0
-150 0 -34 52 c-49 74 -110 110 -197 114 -39 2 -81 -2 -99 -9z m119 -168 c47
-21 60 -80 27 -122 -40 -51 -111 -42 -135 17 -17 41 -8 76 27 100 26 19 48 20
81 5z m872 -1124 c28 -27 33 -79 11 -107 -31 -41 -101 -41 -132 0 -38 49 4
132 66 132 19 0 40 -9 55 -25z'
                      />
                      <path
                        d='M4255 4331 c-100 -45 -155 -130 -155 -237 0 -89 37 -156 113 -207
l57 -38 0 -281 0 -281 181 -183 181 -182 -12 -37 c-15 -47 -9 -129 14 -176 22
-46 78 -103 117 -119 23 -10 29 -19 29 -43 0 -28 -40 -71 -340 -372 l-340
-341 0 -71 c0 -64 -2 -72 -21 -78 -35 -11 -111 -87 -130 -130 -22 -49 -25
-151 -5 -199 19 -46 79 -110 121 -130 l35 -17 0 -99 0 -100 -39 -19 c-145 -75
-177 -284 -60 -400 98 -99 258 -101 360 -5 71 67 95 205 51 290 -22 44 -106
124 -128 124 -11 0 -14 23 -14 105 l0 104 29 15 c124 65 173 225 105 347 -21
37 -47 65 -82 89 -46 30 -52 38 -52 69 0 33 22 58 340 376 l340 341 0 66 c0
59 2 67 21 73 33 11 98 74 122 119 30 57 30 165 0 222 -27 50 -75 98 -122 120
-47 23 -129 29 -176 14 l-37 -12 -159 158 -159 159 0 245 0 245 36 20 c84 45
143 160 129 248 -16 97 -64 163 -150 203 -62 29 -143 31 -200 5z m158 -182
c19 -23 22 -70 7 -99 -12 -22 -64 -42 -88 -33 -74 26 -84 112 -17 151 27 16
76 7 98 -19z m503 -1273 c58 -50 24 -140 -52 -140 -28 0 -42 7 -60 28 -32 37
-30 71 5 107 36 35 70 37 107 5z m-679 -1368 c51 -48 15 -138 -54 -138 -70 0
-106 78 -62 134 27 34 82 36 116 4z m-3 -679 c57 -45 21 -139 -55 -139 -71 0
-105 85 -54 135 30 31 73 32 109 4z'
                      />
                      <path
                        d='M502 3735 c-121 -53 -184 -184 -148 -307 41 -144 204 -221 344 -163
43 18 132 106 132 131 0 12 72 14 449 14 l449 0 11 -29 c17 -39 78 -97 126
-117 59 -25 152 -22 210 7 196 98 184 379 -20 465 -41 17 -123 18 -176 2 -50
-15 -114 -69 -140 -119 l-19 -39 -440 0 -440 0 -19 39 c-26 50 -90 104 -140
119 -52 16 -139 14 -179 -3z m153 -180 c52 -51 17 -135 -56 -135 -59 0 -86 32
-79 95 6 61 90 86 135 40z m1362 3 c29 -32 34 -53 22 -88 -13 -36 -36 -50 -83
-50 -68 0 -101 86 -51 135 32 33 84 34 112 3z'
                      />
                      <path
                        d='M945 2717 c-142 -55 -207 -190 -159 -330 41 -121 194 -197 313 -156
l32 11 183 -181 183 -181 280 0 279 0 34 -52 c49 -74 113 -111 199 -116 104
-6 187 38 232 123 l24 45 443 0 442 0 10 -24 c14 -37 76 -102 110 -116 l30
-12 0 -618 0 -618 -30 -12 c-37 -15 -97 -81 -118 -129 -23 -55 -19 -149 9
-206 95 -190 361 -191 454 -1 61 124 19 262 -101 332 l-44 26 0 609 0 609 38
19 c89 45 135 130 130 237 -3 58 -9 80 -33 120 -36 57 -115 110 -181 120 -95
14 -200 -36 -249 -121 l-27 -45 -442 0 -443 0 -11 24 c-18 40 -73 96 -116 118
-58 30 -166 31 -222 1 -45 -24 -108 -89 -119 -122 -6 -21 -11 -21 -258 -21
l-252 0 -154 154 -154 154 13 52 c30 125 -27 241 -145 296 -55 26 -129 30
-180 11z m140 -187 c25 -26 25 -84 -1 -110 -10 -11 -33 -22 -51 -26 -27 -5
-37 -1 -63 24 -33 34 -38 66 -14 100 31 45 89 50 129 12z m1291 -531 c18 -36
10 -73 -23 -99 -42 -33 -101 -20 -122 27 -16 35 -14 57 9 86 36 45 109 38 136
-14z m1352 18 c49 -45 11 -137 -58 -137 -87 0 -111 127 -29 159 30 12 57 5 87
-22z m-19 -1692 c32 -16 41 -33 41 -73 -1 -55 -55 -90 -110 -71 -34 12 -50 35
-50 74 0 68 58 102 119 70z'
                      />
                    </g>
                  </svg>
                  <h5 className='mb-3 text-2xl font-semibold tracking-tight ud-font-heading ud-text-dark dark:ud-text-white'>
                    Digital Transformation
                  </h5>
                  <p className='mb-3 font-normal ud-text-base ud-text-dark-text'>
                    Our expert team has a proven track record of helping
                    companies modernize their operations and replace cumbersome
                    manual procedures with sleek, efficient digital ones
                  </p>
                </div>
              </div>
              <div
                className='wow fadeInUp px-2 py-2 md:px-8 lg:px-8 md:py-8 lg:py-8 ud-flex ud-w-full ud-flex-col ud-items-center ud-text-center md:ud-w-1/2 lg:ud-w-1/2'
                data-wow-delay='.3s'
              >
                <div className='p-6 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 ud-service-card-background dark:border-gray-700 ud-flex ud-max-w-xl ud-flex-col ud-items-center ud-text-center dark:ud-bg-[#1D232D]'>
                  <svg
                    version={1.0}
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512.000000 512.000000'
                    preserveAspectRatio='xMidYMid meet'
                    className='ud-service-svg-color ud-service-image'
                  >
                    <g
                      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
                      stroke='none'
                    >
                      <path
                        d='M2010 5114 c-286 -30 -494 -99 -718 -236 -247 -151 -483 -414 -600
            -668 -108 -237 -147 -409 -158 -707 l-7 -174 -264 -477 -264 -477 4 -57 c10
            -117 91 -229 194 -268 30 -11 66 -20 80 -20 58 0 151 -23 198 -48 l50 -27 6
            -225 c5 -175 10 -235 23 -270 60 -161 182 -258 352 -281 48 -6 217 -9 425 -7
            l344 3 68 33 c49 24 79 48 107 83 66 83 70 112 70 455 l0 302 33 13 c48 20
            115 86 145 144 23 43 27 62 27 140 0 106 -20 158 -88 225 -51 51 -96 75 -167
            88 -218 40 -408 -150 -368 -368 18 -97 72 -172 163 -226 l45 -26 0 -279 c0
            -298 -5 -335 -49 -359 -15 -7 -137 -10 -405 -8 l-384 3 -38 31 c-81 65 -78 55
            -84 342 -3 179 -9 264 -17 279 -16 27 -83 81 -145 117 -62 35 -157 63 -241 72
            -75 8 -127 38 -127 76 0 10 111 220 247 466 135 246 254 465 264 487 16 34 19
            68 19 210 0 222 19 340 80 512 164 462 576 809 1065 898 105 19 638 33 694 18
            19 -5 44 -21 55 -37 20 -26 21 -43 24 -265 l3 -239 -45 -26 c-125 -73 -187
            -199 -166 -334 37 -227 299 -340 492 -212 50 33 109 113 128 174 19 60 8 171
            -23 231 -28 57 -96 124 -144 144 l-32 13 -3 266 -3 267 -28 57 c-32 65 -89
            121 -156 152 -44 20 -62 21 -346 22 -165 1 -316 0 -335 -2z m810 -969 c66 -34
            76 -121 19 -169 -94 -79 -223 42 -148 138 36 46 80 56 129 31z m-941 -1721
            c58 -48 47 -135 -20 -170 -68 -35 -149 18 -149 96 0 30 7 46 31 69 40 41 94
            43 138 5z'
                      />
                      <path
                        d='M3578 4784 c-49 -26 -58 -59 -58 -216 l0 -143 -75 -34 c-42 -18 -78
            -38 -81 -42 -3 -5 18 -50 46 -99 l51 -90 57 29 c31 15 80 36 108 45 57 19 91
            46 99 79 3 12 6 78 8 147 l2 125 105 0 105 0 5 -140 c3 -84 10 -149 17 -162 7
            -12 34 -29 60 -39 27 -9 87 -34 134 -55 129 -58 133 -57 253 61 l102 100 77
            -77 77 -77 -100 -102 c-117 -118 -120 -128 -67 -239 19 -39 41 -93 51 -121 19
            -57 46 -91 79 -99 12 -3 78 -6 147 -8 l125 -2 0 -105 0 -105 -125 -2 c-69 -2
            -135 -5 -147 -8 -33 -8 -60 -42 -79 -99 -10 -28 -32 -82 -51 -121 -53 -111
            -50 -121 67 -239 l100 -102 -77 -77 -77 -77 -102 100 c-118 117 -128 120 -239
            67 -39 -19 -93 -41 -121 -51 -57 -19 -91 -46 -99 -79 -3 -12 -6 -78 -8 -147
            l-2 -125 -105 0 -105 0 -2 125 c-2 69 -5 135 -8 147 -8 33 -42 60 -99 79 -28
            10 -82 32 -121 51 -111 53 -121 50 -239 -67 l-102 -100 -77 77 -77 77 100 102
            c117 118 120 128 67 239 -19 39 -41 93 -51 120 -34 102 -42 105 -270 105
            l-186 0 0 -105 0 -105 134 0 133 0 18 -46 17 -45 -104 -107 c-160 -164 -160
            -162 66 -388 226 -226 224 -226 388 -66 l107 104 45 -17 46 -18 0 -144 c0
            -235 -3 -233 320 -233 323 0 320 -2 320 233 l0 144 46 18 45 17 107 -104 c164
            -160 162 -160 388 66 226 226 226 224 66 388 l-104 107 17 45 18 46 144 0
            c235 0 233 -3 233 320 0 323 2 320 -233 320 l-144 0 -18 46 -17 45 104 107
            c160 164 160 162 -66 388 -226 226 -224 226 -388 66 l-107 -104 -45 17 -46 18
            0 144 c0 236 3 233 -322 233 -187 -1 -238 -4 -260 -16z'
                      />
                      <path
                        d='M3773 3940 c-222 -33 -385 -254 -353 -481 48 -342 474 -484 720 -239
            288 289 38 779 -367 720z m180 -242 c22 -14 51 -43 65 -65 23 -33 27 -48 27
            -113 0 -65 -4 -80 -27 -113 -14 -22 -43 -51 -65 -65 -33 -23 -48 -27 -113 -27
            -65 0 -80 4 -113 27 -61 41 -91 93 -95 164 -6 92 36 165 120 208 51 26 152 18
            201 -16z'
                      />
                      <path
                        d='M1070 3333 c1 -130 57 -210 162 -233 63 -13 353 -13 416 0 105 23
            161 103 162 233 l0 77 -105 0 -105 0 0 -50 0 -50 -160 0 -160 0 0 50 0 50
            -105 0 -105 0 0 -77z'
                      />
                      <path d='M2240 3305 l0 -105 105 0 105 0 0 105 0 105 -105 0 -105 0 0 -105z' />
                      <path d='M2240 2880 l0 -110 105 0 105 0 0 110 0 110 -105 0 -105 0 0 -110z' />
                      <path
                        d='M2900 2121 c-133 -43 -219 -151 -228 -287 -5 -85 7 -130 55 -201 129
            -192 420 -179 535 24 l30 53 565 -2 565 -3 29 -33 c29 -32 29 -33 29 -157 0
            -109 -3 -133 -26 -197 -35 -96 -75 -166 -132 -229 -74 -82 -133 -112 -254
            -129 -45 -7 -68 -16 -87 -35 l-26 -25 -5 -343 -5 -342 -1012 -3 -1013 -2 0
            160 0 160 -105 0 -105 0 0 -230 0 -230 29 -32 29 -33 1151 -3 c1266 -3 1193
            -6 1226 58 13 24 15 84 15 363 l0 335 34 6 c60 11 175 76 237 134 105 97 194
            242 239 389 19 63 21 90 18 240 -3 150 -5 173 -25 213 -31 63 -88 119 -153
            149 l-55 26 -582 3 -583 2 -20 41 c-27 53 -90 112 -148 141 -50 24 -173 35
            -222 19z m163 -241 c39 -44 38 -98 -4 -139 -81 -82 -218 15 -165 118 35 67
            118 77 169 21z'
                      />
                      <path d='M1710 855 l0 -105 105 0 105 0 0 105 0 105 -105 0 -105 0 0 -105z' />
                    </g>
                  </svg>
                  <h5 className='mb-3 text-2xl font-semibold tracking-tight ud-font-heading ud-text-dark dark:ud-text-white'>
                    Product Engineering
                  </h5>
                  <p className='mb-3 font-normal ud-text-base ud-text-dark-text'>
                    We understand that every business is unique, and we work
                    closely with our clients to understand their unique needs,
                    and we use cutting-edge technologies to build their
                    expectations
                  </p>
                </div>
              </div>
              <div
                className='wow fadeInUp px-2 py-2 md:px-8 lg:px-8 md:py-8 lg:py-8 ud-flex ud-w-full ud-flex-col ud-items-center ud-text-center md:ud-w-1/2 lg:ud-w-1/2'
                data-wow-delay='.3s'
              >
                <div className='p-6 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 ud-service-card-background dark:border-gray-700 ud-flex ud-max-w-xl ud-flex-col ud-items-center ud-text-center dark:ud-bg-[#1D232D]'>
                  <svg
                    version={1.0}
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512.000000 512.000000'
                    preserveAspectRatio='xMidYMid meet'
                    className='ud-service-svg-color ud-service-image'
                  >
                    <g
                      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
                      stroke='none'
                    >
                      <path
                        d='M4795 4589 c-121 -77 -226 -147 -232 -156 -19 -25 -16 -70 7 -93 40
-40 69 -29 297 117 118 75 223 148 234 161 24 32 24 53 -2 86 -38 49 -64 40
-304 -115z'
                      />
                      <path
                        d='M21 4484 c-28 -36 -26 -65 6 -96 16 -14 280 -170 588 -348 308 -177
561 -323 563 -325 3 -2 -966 -1684 -978 -1698 -4 -5 -32 4 -63 19 -65 32 -101
27 -126 -18 -32 -56 -15 -72 214 -206 116 -67 221 -125 233 -128 32 -9 69 21
107 84 l31 50 78 -41 c64 -35 77 -45 72 -62 -2 -11 -10 -40 -16 -65 -24 -96
13 -212 91 -285 74 -69 126 -89 232 -89 l87 1 0 -31 c0 -49 52 -148 100 -189
67 -57 139 -80 233 -75 l77 5 0 -27 c0 -88 88 -208 182 -251 43 -19 69 -24
141 -23 84 0 87 0 87 -23 0 -34 34 -116 65 -157 33 -44 106 -93 158 -107 57
-15 145 -13 196 6 58 20 263 168 311 223 l37 43 154 -84 c204 -111 231 -121
321 -115 159 10 278 125 294 283 l6 60 62 0 c154 1 286 127 303 290 l6 53 71
4 c159 11 279 128 293 287 l5 56 63 1 c79 1 179 46 225 102 80 98 97 200 55
338 -5 16 5 28 53 62 33 23 64 42 69 41 4 0 27 -25 51 -55 64 -81 80 -78 231
42 79 62 123 104 127 120 13 53 -37 104 -88 90 -13 -3 -57 -33 -98 -66 -41
-33 -77 -60 -80 -59 -7 1 -1192 1561 -1188 1563 2 2 159 103 351 225 255 164
349 229 353 246 10 38 0 67 -29 89 -23 17 -32 18 -55 10 -27 -10 -707 -444
-769 -491 -53 -39 -47 -74 33 -180 37 -48 68 -93 71 -99 2 -7 -16 -28 -42 -47
l-46 -35 -192 64 -191 64 -466 0 c-394 0 -469 -2 -487 -15 -15 -10 -28 -44
-48 -125 -23 -93 -30 -110 -44 -104 -9 3 -44 16 -77 28 l-61 22 -178 -20 -178
-20 -54 30 c-30 16 -57 32 -59 36 -3 4 20 50 51 102 68 117 75 144 43 178 -12
14 -352 215 -754 447 -528 304 -740 421 -762 421 -21 0 -37 -8 -51 -26z m1401
-925 c-3 -10 -968 -1688 -977 -1697 -4 -4 -115 62 -115 70 0 3 221 389 491
856 l490 851 57 -34 c31 -19 56 -40 54 -46z m1892 -243 c188 -60 224 -70 252
-67 6 0 41 24 77 51 36 28 70 50 74 50 8 -1 884 -1148 890 -1167 2 -6 -26 -32
-63 -58 l-66 -48 -37 21 c-20 11 -376 206 -791 432 -415 227 -756 413 -757
414 -2 1 11 24 28 50 43 69 47 99 16 130 -33 33 -76 34 -104 4 -12 -13 -56
-79 -100 -148 l-78 -125 -5 -365 c-5 -344 -6 -368 -26 -410 -44 -94 -122 -145
-224 -145 -103 0 -180 50 -224 145 -20 42 -21 65 -24 375 l-3 330 71 285 c39
157 73 291 75 298 3 9 95 12 411 12 l406 0 202 -64z m-1813 -133 l75 -42 184
21 c179 21 184 21 237 3 90 -31 87 -19 42 -199 l-39 -160 0 -305 c0 -338 8
-421 46 -499 131 -268 514 -294 679 -46 66 99 68 111 72 502 4 324 6 353 21
350 10 -3 366 -195 792 -427 524 -285 785 -433 807 -455 57 -61 56 -150 -3
-215 -41 -46 -101 -66 -155 -51 -19 6 -226 114 -460 241 -233 127 -438 237
-454 244 -41 18 -91 -2 -101 -41 -16 -62 -10 -66 351 -263 483 -264 449 -243
474 -294 26 -53 26 -89 2 -143 -28 -62 -73 -89 -146 -89 -59 0 -65 3 -439 208
-209 115 -391 211 -405 213 -18 4 -35 -2 -54 -16 -38 -30 -37 -80 1 -112 16
-12 158 -93 317 -178 158 -85 302 -169 320 -185 39 -36 60 -99 51 -150 -16
-82 -119 -149 -198 -127 -19 5 -180 89 -359 186 -178 97 -334 176 -348 176
-57 0 -91 -74 -53 -118 9 -11 131 -82 270 -158 150 -81 262 -148 277 -166 93
-111 20 -268 -126 -268 -35 0 -75 18 -231 102 -170 92 -188 105 -188 128 0
124 -121 261 -252 285 l-35 7 -5 73 c-6 94 -41 168 -109 229 -47 43 -119 76
-164 76 -20 0 -22 5 -22 73 0 57 -5 83 -27 129 -45 95 -158 178 -243 178 -22
0 -23 3 -23 73 0 62 -5 83 -31 135 -76 153 -250 219 -403 152 -22 -9 -164
-106 -314 -214 l-272 -197 -92 48 c-50 26 -92 51 -92 54 -1 5 532 934 687
1197 27 46 52 82 55 80 4 -2 41 -22 83 -45z m150 -1069 c43 -22 72 -71 77
-129 5 -58 -10 -95 -55 -136 -49 -47 -545 -401 -582 -416 -88 -37 -201 31
-216 130 -6 38 -3 55 16 94 22 42 54 68 313 255 158 114 302 212 320 217 36
11 89 5 127 -15z m308 -392 c64 -47 84 -145 45 -213 -20 -33 -393 -315 -476
-359 -75 -40 -170 -18 -215 51 -22 33 -25 47 -21 96 2 33 12 70 22 86 17 25
406 316 466 347 60 32 130 29 179 -8z m271 -367 c41 -21 81 -78 87 -123 7 -53
-10 -102 -50 -139 -66 -63 -339 -253 -373 -259 -73 -13 -146 22 -179 87 -19
36 -19 112 -1 147 8 15 27 39 43 53 29 27 294 218 328 237 29 16 111 15 145
-3z m308 -392 c46 -34 72 -81 72 -130 0 -74 -24 -108 -127 -185 -136 -100
-151 -108 -207 -108 -161 0 -226 193 -98 294 49 39 170 124 198 140 39 22 126
16 162 -11z'
                      />
                    </g>
                  </svg>
                  <h5 className='mb-3 text-2xl font-semibold tracking-tight ud-font-heading ud-text-dark dark:ud-text-white'>
                    Co-Development
                  </h5>
                  <p className='mb-3 font-normal ud-text-base ud-text-dark-text'>
                    Co-development model is designed to provide businesses with
                    the support and guidance they need to bring their ideas to
                    life, while also allowing them to retain ownership of the
                    development process
                  </p>
                </div>
              </div>
              <div
                className='wow fadeInUp px-2 py-2 md:px-8 lg:px-8 md:py-8 lg:py-8 ud-flex ud-w-full ud-flex-col ud-items-center ud-text-center md:ud-w-1/2 lg:ud-w-1/2'
                data-wow-delay='.3s'
              >
                <div className='p-6 bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 ud-service-card-background dark:border-gray-700 ud-flex ud-max-w-xl ud-flex-col ud-items-center ud-text-center dark:ud-bg-[#1D232D]'>
                  <svg
                    version={1.0}
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512.000000 512.000000'
                    preserveAspectRatio='xMidYMid meet'
                    className='ud-service-svg-color ud-service-image'
                  >
                    <g
                      transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
                      stroke='none'
                    >
                      <path
                        d='M2470 5114 c-14 -2 -56 -9 -95 -15 -151 -25 -327 -111 -456 -224 -75
-66 -132 -134 -198 -238 -52 -81 -67 -86 -176 -64 -44 10 -105 17 -135 17
-135 -1 -306 -65 -407 -155 -68 -60 -145 -176 -173 -261 -32 -94 -54 -114
-127 -114 -87 0 -186 -25 -272 -67 -103 -51 -223 -169 -273 -270 -167 -334 -5
-738 345 -864 51 -18 90 -23 220 -27 l157 -4 0 -337 0 -336 -152 -153 -152
-152 -51 0 c-152 0 -229 -188 -121 -296 35 -35 104 -59 146 -50 24 4 64 -33
398 -367 l371 -372 2 -172 1 -173 -30 -30 c-39 -38 -56 -89 -50 -149 12 -121
146 -189 253 -128 104 59 122 185 40 273 l-35 37 0 194 c0 149 -3 200 -14 221
-8 15 -188 200 -400 411 l-384 384 -1 46 0 46 177 178 177 177 3 375 3 375
219 0 220 0 0 -366 0 -367 -34 -38 c-78 -85 -63 -201 34 -267 46 -31 134 -31
180 0 97 66 111 190 31 272 l-31 32 0 367 0 367 880 0 880 0 0 -631 0 -632
-34 -38 c-77 -85 -66 -192 26 -259 33 -25 51 -30 95 -30 30 0 67 7 82 15 63
32 109 121 95 182 -9 39 -51 110 -69 117 -13 5 -15 80 -15 641 l0 635 220 0
220 0 0 -372 0 -372 179 -180 179 -181 -1 -47 -2 -47 -397 -399 -398 -398 0
-206 0 -206 -34 -34 c-32 -32 -56 -89 -56 -133 1 -37 37 -102 73 -129 86 -65
211 -34 263 65 30 59 12 151 -40 198 l-26 24 0 176 0 176 369 369 369 369 55
2 c119 3 197 103 167 214 -21 81 -88 131 -172 131 -42 0 -46 3 -196 153 l-152
152 0 331 0 331 138 6 c219 10 346 60 471 186 187 188 233 471 114 711 -51
104 -169 221 -274 273 -85 42 -185 67 -269 67 -33 0 -64 6 -77 15 -12 8 -33
48 -48 87 -39 103 -90 184 -161 254 -134 132 -359 201 -534 163 -146 -31 -144
-32 -224 89 -147 223 -365 376 -609 428 -72 15 -252 26 -297 18z m226 -465
c67 -25 149 -105 182 -178 49 -108 38 -244 -28 -342 l-30 -44 0 -428 0 -428
-25 -24 c-24 -25 -25 -25 -232 -25 -234 0 -244 3 -263 68 -6 23 -10 193 -10
439 l0 402 -37 68 c-36 65 -38 72 -38 163 0 82 4 103 27 152 80 172 270 246
454 177z'
                      />
                      <path
                        d='M2480 4478 c-107 -55 -127 -179 -45 -278 l35 -42 0 -404 0 -405 88 3
87 3 5 405 5 405 32 40 c40 52 54 101 42 156 -13 58 -44 98 -93 120 -55 25
-103 24 -156 -3z'
                      />
                      <path
                        d='M2470 1628 l0 -753 -46 -19 c-64 -26 -148 -107 -180 -173 -39 -79
-45 -194 -14 -275 28 -76 104 -159 178 -195 50 -25 68 -28 152 -28 83 0 102 3
151 28 67 32 142 109 172 175 18 38 22 65 22 142 0 84 -3 102 -28 152 -33 68
-116 148 -181 174 l-46 19 0 448 0 447 85 0 85 0 0 40 0 40 -85 0 -85 0 0 220
0 220 175 0 175 0 0 45 0 45 -265 0 -265 0 0 -752z m196 -857 c52 -24 111 -83
135 -135 25 -54 25 -157 0 -211 -45 -100 -131 -155 -241 -155 -152 0 -260 108
-260 260 0 107 49 188 142 234 65 33 161 35 224 7z'
                      />
                      <path
                        d='M2524 606 c-44 -19 -62 -67 -41 -110 21 -44 66 -63 108 -45 69 28 70
127 3 155 -39 16 -32 16 -70 0z'
                      />
                      <path
                        d='M400 1053 c-34 -7 -88 -49 -111 -87 -37 -61 -17 -177 37 -213 l24
-15 0 -327 c0 -290 2 -330 18 -361 33 -66 126 -63 152 6 6 14 10 167 10 349
l0 323 34 34 c62 62 67 156 14 231 -33 47 -112 73 -178 60z'
                      />
                      <path
                        d='M4626 695 c-83 -29 -136 -114 -121 -194 7 -38 38 -88 67 -109 16 -12
18 -31 18 -173 l0 -161 29 -29 c33 -33 58 -36 101 -14 42 22 52 67 48 225 l-3
135 26 22 c72 61 79 183 16 251 -46 50 -120 69 -181 47z'
                      />
                    </g>
                  </svg>
                  <h5 className='mb-3 text-2xl font-semibold tracking-tight ud-font-heading ud-text-dark dark:ud-text-white'>
                    Cloud Migration
                  </h5>
                  <p className='mb-3 font-normal ud-text-base ud-text-dark-text'>
                    Our cloud migration services are designed to help businesses
                    of all sizes and industries migrate their operations to the
                    cloud, providing them with the benefits of increased
                    flexibility, scalability, and security
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ===== Features Section End ===== */}
        {/* ===== Portfolio Section Start ===== */}
        <section
          id='portfolio'
          className='ud-pt-14 sm:ud-pt-20 lg:ud-pt-[130px]'
        >
          <div
            className='wow fadeInUp ud-relative ud-mx-auto ud-mb-12 ud-max-w-[620px] ud-pt-6 ud-text-center md:ud-mb-20 lg:ud-pt-16'
            data-wow-delay='.2s'
          >
            <span className='title'> PORTFOLIO </span>
            <h2 className='ud-mx-auto ud-mb-5 ud-max-w-[480px] ud-font-heading ud-text-3xl ud-font-semibold ud-text-dark dark:ud-text-white sm:ud-text-4xl md:ud-text-[50px] md:ud-leading-[60px]'>
              Explore Our Latest Case Studies
            </h2>
            <p className='ud-text-base ud-text-dark-text'>
              We take great satisfaction in providing our customers with
              innovative, state-of-the-art solutions with cutting-edge
              technologies.
            </p>
          </div>
          <div className='gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6'>
            <img
              className='w-full ud-order-last'
              src={Nawinne}
              alt='Nawinne Dashboard'
            />
            <div className='mt-4 ud-order-first'>
              <h2 className='mb-4 text-4xl tracking-tight font-extrabold ud-text-dark dark:ud-text-white'>
                Nawinne - Hospital Management System
              </h2>
              <p className='mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400'>
                <p className=''>
                  The Nawinna Hospital Management System is a robust platform by
                  integrating,
                </p>
                <ul className='ml-6 font-bold'>
                  <li className=''>Inventory Management</li>
                  <li className=''>Supplier Management</li>
                  <li className=''>Employee Management</li>
                  <li className=''>Finance management modules.</li>
                </ul>
                <p className='margin-top: 0; padding-top: 0;'>
                  This comprehensive system streamlines the hospital&apos;s
                  day-to-day operations, allowing efficient tracking and
                  management of inventory levels, ensuring timely supply chain
                  management with suppliers, optimizing staff scheduling and
                  performance, and handling financial aspects seamlessly.
                </p>
              </p>
            </div>
          </div>
          <div className='gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6'>
            <img
              className='w-full dark:ud-hidden'
              src={PlugseekWhite}
              alt='PlugseekWhite dashboard'
            />
            <img
              className='w-full hidden dark:ud-block'
              src={PlugseekDark}
              alt='PlugseekDark dashboard'
            />
            <div className='mt-4 md:mt-0'>
              <h2 className='mb-4 tracking-tight font-extrabold ud-text-dark dark:ud-text-white text-2xl sm:ud-text-3xl md:ud-text-4xl'>
                Plugseek - A New Era Of Traveling
              </h2>
              <p className='mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400'>
                Do you own an electric vehicle? Worried About Covering Long
                Distances in Sri Lanka with Your EV? Plugseek is prepared to
                simplify your travel experience. It is no longer necessary to
                stress out over Sri Lanka's long distances. Simply said,
                Plugseek will show you where the closest electric vehicle
                charging outlet is in Sri Lanka.
              </p>
              <div
                className='wow fadeInUp ud-flex ud-flex-wrap ud-items-center ud-justify-center md:ud-justify-start'
                data-wow-delay='.5s'
              >
                <a
                  href='https://www.plugseek.com/'
                  target='_blank'
                  rel='noreferrer'
                  className='ud-inline-flex ud-items-center ud-rounded ud-bg-primary ud-py-[10px] ud-px-6 ud-font-heading ud-text-base ud-text-white hover:ud-bg-opacity-90 md:ud-py-[14px] md:ud-px-8'
                >
                  See Product
                  <span className='ud-pl-3'>
                    <svg
                      width={16}
                      height={16}
                      viewBox='0 0 16 16'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.172 7L6.808 1.636L8.222 0.222L16 8L8.222 15.778L6.808 14.364L12.172 9H0V7H12.172Z'
                        fill='white'
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* ===== Portfolio Section End ===== */}
        {/* ===== Portfolio Section Start ===== */}
        {/* ===== Portfolio Section End ===== */}
        {/* ===== About Section Start ===== */}
        <section id='about' className='ud-pt-14 sm:ud-pt-20 lg:ud-pt-[130px]'>
          <About />
        </section>
        {/* ===== About Section End ===== */}

        {/* ===== Blogs Section Start ===== */}
        <section id='blogs' className='ud-pt-14 sm:ud-pt-20 lg:ud-pt-[130px]'>
          <Blogs />
        </section>
        {/* ===== Blogs Section End ===== */}

        {/* ===== Support Section Start ===== */}
        <section id='support' className='ud-pt-14 sm:ud-pt-20 lg:ud-pt-[130px]'>
          <div className='ud-px-4 xl:ud-container'>
            <div className='ud--mx-4 ud-flex ud-flex-wrap ud-justify-center'>
              <div className='ud-w-full ud-px-4 xl:ud-w-10/12'>
                <div className='ud-flex ud-flex-wrap ud-items-center ud-border-b ud-pb-14 dark:ud-border-[#2E333D] lg:ud-pb-0'>
                  <div className='ud-w-full ud-px-4 lg:ud-w-1/2'>
                    {/* Section Title */}
                    <div
                      className='wow fadeInUp ud-relative ud-mb-12 ud-max-w-[500px] ud-pt-6 md:ud-mb-14 lg:ud-pt-16'
                      data-wow-delay='.2s'
                    >
                      <span className='title !ud-left-0 !ud-translate-x-0'>
                        SUPPORT
                      </span>
                      <h2 className='ud-mb-5 ud-font-heading ud-text-3xl ud-font-semibold ud-text-dark dark:ud-text-white sm:ud-text-4xl md:ud-text-[50px] md:ud-leading-[60px]'>
                        Need Any Help? Say Hello
                      </h2>
                      <p className='ud-text-base ud-text-dark-text'>
                        If you have any questions about our products or
                        services, or if you would like to learn more about how
                        we can help your business, please don't hesitate to
                        contact us.
                      </p>
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4 lg:ud-w-1/2'>
                    <div
                      className='wow fadeInUp ud-flex ud-items-center'
                      data-wow-delay='.2s'
                    >
                      <span className='ud-mr-10 ud-h-1 ud-w-full ud-max-w-[200px] ud-bg-dark dark:ud-bg-white' />
                      <a
                        href='mailto:hello@phrasecode.com'
                        className='ud-font-heading ud-text-xl ud-text-dark dark:ud-text-white md:ud-text-3xl lg:ud-text-xl xl:ud-text-3xl'
                      >
                        hello@phrasecode.com
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className='wow fadeInUp ud--mx-4 ud-flex ud-flex-wrap ud-pt-12 text-left pl-8'
                  data-wow-delay='.2s'
                >
                  <div className='ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/4'>
                    <div className='ud-mb-6'>
                      <h3 className='ud-mb-2 ud-font-heading ud-text-base ud-text-dark dark:ud-text-white sm:ud-text-xl'>
                        Email Address
                      </h3>
                      <p className='ud-text-base ud-font-medium ud-text-dark-text'>
                        <a href='mailto:hello@phrasecode.com'>
                          hello@phrasecode.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/4'>
                    <div className='ud-mb-6'>
                      <h3 className='ud-mb-2 ud-font-heading ud-text-base ud-text-dark dark:ud-text-white sm:ud-text-xl'>
                        Phone Number
                      </h3>
                      <p className='ud-text-base ud-font-medium ud-text-dark-text'>
                        <a href='tel:+94741460800'> +94 74 146 0800 </a>
                      </p>
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/4'>
                    <div className='ud-mb-6'>
                      <h3 className='ud-mb-2 ud-font-heading ud-text-base ud-text-dark dark:ud-text-white sm:ud-text-xl'>
                        Office Location
                      </h3>
                      <p className='ud-text-base ud-font-medium ud-text-dark-text'>
                        Negombo, Sri Lanka.
                      </p>
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/4'>
                    <div className='ud-mb-6 ud-flex ud-items-center ud-space-x-5 lg:ud-justify-end'>
                      <a
                        href='https://www.facebook.com/info.phrasecode'
                        target='_blank'
                        rel='noreferrer'
                        name='social-link'
                        aria-label='social-link'
                        className='ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                      >
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          className='ud-fill-current'
                        >
                          <path d='M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47 14 5.5 16 5.5H17.5V2.14C17.174 2.097 15.943 2 14.643 2C11.928 2 10 3.657 10 6.7V9.5H7V13.5H10V22H14V13.5Z' />
                        </svg>
                      </a>
                      <a
                        href='https://twitter.com/infophrasecode'
                        target='_blank'
                        rel='noreferrer'
                        name='social-link'
                        aria-label='social-link'
                        className='ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                      >
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          className='ud-fill-current'
                        >
                          <path d='M22.1621 5.65593C21.3986 5.99362 20.589 6.2154 19.7601 6.31393C20.6338 5.79136 21.2878 4.96894 21.6001 3.99993C20.7801 4.48793 19.8811 4.82993 18.9441 5.01493C18.3147 4.34151 17.4804 3.89489 16.571 3.74451C15.6616 3.59413 14.728 3.74842 13.9153 4.18338C13.1026 4.61834 12.4564 5.30961 12.0772 6.14972C11.6979 6.98983 11.6068 7.93171 11.8181 8.82893C10.1552 8.74558 8.52838 8.31345 7.04334 7.56059C5.55829 6.80773 4.24818 5.75098 3.19805 4.45893C2.82634 5.09738 2.63101 5.82315 2.63205 6.56193C2.63205 8.01193 3.37005 9.29293 4.49205 10.0429C3.82806 10.022 3.17868 9.84271 2.59805 9.51993V9.57193C2.59825 10.5376 2.93242 11.4735 3.5439 12.221C4.15538 12.9684 5.00653 13.4814 5.95305 13.6729C5.33667 13.84 4.69036 13.8646 4.06305 13.7449C4.32992 14.5762 4.85006 15.3031 5.55064 15.824C6.25123 16.345 7.09718 16.6337 7.97005 16.6499C7.10253 17.3313 6.10923 17.8349 5.04693 18.1321C3.98464 18.4293 2.87418 18.5142 1.77905 18.3819C3.69075 19.6114 5.91615 20.2641 8.18905 20.2619C15.8821 20.2619 20.0891 13.8889 20.0891 8.36193C20.0891 8.18193 20.0841 7.99993 20.0761 7.82193C20.8949 7.2301 21.6017 6.49695 22.1631 5.65693L22.1621 5.65593Z' />
                        </svg>
                      </a>
                      <a
                        href='https://www.linkedin.com/company/phrasecode/'
                        target='_blank'
                        rel='noreferrer'
                        name='social-link'
                        aria-label='social-link'
                        className='ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                      >
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          className='ud-fill-current'
                        >
                          <path d='M6.93994 5.00002C6.93968 5.53046 6.72871 6.03906 6.35345 6.41394C5.97819 6.78883 5.46938 6.99929 4.93894 6.99902C4.40851 6.99876 3.89991 6.78779 3.52502 6.41253C3.15014 6.03727 2.93968 5.52846 2.93994 4.99802C2.94021 4.46759 3.15117 3.95899 3.52644 3.5841C3.9017 3.20922 4.41051 2.99876 4.94094 2.99902C5.47137 2.99929 5.97998 3.21026 6.35486 3.58552C6.72975 3.96078 6.94021 4.46959 6.93994 5.00002ZM6.99994 8.48002H2.99994V21H6.99994V8.48002ZM13.3199 8.48002H9.33994V21H13.2799V14.43C13.2799 10.77 18.0499 10.43 18.0499 14.43V21H21.9999V13.07C21.9999 6.90002 14.9399 7.13002 13.2799 10.16L13.3199 8.48002Z' />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='ud-mx-auto ud-max-w-[780px] ud-pt-8'>
              <div
                className='wow fadeInUp'
                data-wow-delay='.2s'
                id='contactForm'
              >
                <div className='ud--mx-4 ud-flex ud-flex-wrap text-left'>
                  <div className='ud-w-full ud-px-4 sm:ud-w-1/2'>
                    <div className='ud-mb-12'>
                      <label
                        htmlFor='name'
                        className='ud-mb-3 ud-block ud-font-heading ud-text-base ud-text-dark dark:ud-text-white'
                      >
                        Your Name
                      </label>
                      <input
                        type='text'
                        name='name'
                        id='name'
                        placeholder='Full Name'
                        className='border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ud-bg-transparent ud-font-medium ud-text-dark ud-placeholder-dark-text dark:ud-border-[#2C3443] dark:ud-text-white dark:focus:ud-border-white'
                      />
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4 sm:ud-w-1/2'>
                    <div className='ud-mb-12'>
                      <label
                        htmlFor='email'
                        className='ud-mb-3 ud-block ud-font-heading ud-text-base ud-text-dark dark:ud-text-white'
                      >
                        Email Address
                      </label>
                      <input
                        type='email'
                        name='email'
                        id='email'
                        placeholder='Email Address'
                        className='border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ud-bg-transparent ud-font-medium ud-text-dark ud-placeholder-dark-text dark:ud-border-[#2C3443] dark:ud-text-white dark:focus:ud-border-white'
                      />
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4 sm:ud-w-1/2'>
                    <div className='ud-mb-12'>
                      <label
                        htmlFor='phone'
                        className='ud-mb-3 ud-block ud-font-heading ud-text-base ud-text-dark dark:ud-text-white'
                      >
                        Phone (Optional)
                      </label>
                      <input
                        type='text'
                        name='phone'
                        id='phone'
                        placeholder='Phone Number'
                        className='border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ud-bg-transparent ud-font-medium ud-text-dark ud-placeholder-dark-text dark:ud-border-[#2C3443] dark:ud-text-white dark:focus:ud-border-white'
                      />
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4 sm:ud-w-1/2'>
                    <div className='ud-mb-12'>
                      <label
                        htmlFor='subject'
                        className='ud-mb-3 ud-block ud-font-heading ud-text-base ud-text-dark dark:ud-text-white'
                      >
                        Subject
                      </label>
                      <input
                        type='text'
                        name='subject'
                        id='subject'
                        placeholder='Type Subject'
                        className='border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ud-bg-transparent ud-font-medium ud-text-dark ud-placeholder-dark-text dark:ud-border-[#2C3443] dark:ud-text-white dark:focus:ud-border-white'
                      />
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4'>
                    <div className='ud-mb-4'>
                      <label
                        htmlFor='message'
                        className='ud-mb-3 ud-block ud-font-heading ud-text-base ud-text-dark dark:ud-text-white'
                      >
                        Message
                      </label>
                      <textarea
                        rows={4}
                        name='message'
                        id='message'
                        placeholder='Type Message'
                        className='border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ud-bg-transparent ud-font-medium ud-text-dark ud-placeholder-dark-text dark:ud-border-[#2C3443] dark:ud-text-white dark:focus:ud-border-white'
                        defaultValue={''}
                      />
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4'>
                    <div className='ud-mb-12'>
                      <label
                        htmlFor='supportCheckbox'
                        className='ud-danger-text ud-flex'
                        id='supportCheckbox'
                      ></label>
                    </div>
                  </div>
                  <div className='ud-w-full ud-px-4'>
                    <button
                      className='ud-flex ud-w-full ud-items-center ud-justify-center ud-rounded ud-bg-primary ud-py-[14px] ud-px-8 ud-font-heading ud-text-base ud-text-white hover:ud-bg-opacity-90'
                      onClick={submitGetInTouch}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ===== Support Section End ===== */}

        {/* ===== Footer Start ===== */}
        <Footer />
        {/* ===== Footer End  ===== */}
        {/* ====== Back To Top Start ===== */}
        <div
          onClick={backToTopFunc}
          className='ud-cursor-pointer hover:ud-shadow-signUp back-to-top ud-fixed ud-bottom-8 ud-right-8 ud-left-auto ud-z-[999] ud-hidden ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-full ud-bg-primary ud-text-white ud-shadow-md ud-transition'
        >
          <span className='ud-mt-[6px] ud-h-3 ud-w-3 ud-rotate-45 ud-border-t ud-border-l ud-border-white' />
        </div>
        {/* ====== Back To Top End ===== */}
        {/* ===== JS Files ===== */}
        {/*  */}
      </div>
    </div>
  );
}

export default Home;
