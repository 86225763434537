import { Header } from '../../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useEffect, useState } from 'react';
import { getBlog } from '../../services';
import { useParams } from 'react-router-dom';
import { Footer } from '../../components/footer';
import { SEODecorator } from '../../shared/SEODecorator';

const BlogView = () => {
  const [blog, setBlog] = useState([]);
  let { id } = useParams();

  useEffect(() => {
    if (id) {
      async function fetchData() {
        const data = await getBlog(id);
        setBlog(data);
      }
      fetchData();
    }
  }, [id]);

  return (
    <Fragment>
      <SEODecorator
        title={blog.title}
        description={blog.description}
        imageUrl={blog.imageUrl}
        imageAlt={blog.title}
        type={'article'}
        author={blog.author}
      />
      <div className=''>
        <Header isDarkModeOff={true} />

        <section className='ud-pt-24'>
          <div className='ud-container'>
            <h2 className='ud-mb-5 ud-text-center ud-font-heading ud-text-3xl ud-text-dark dark:ud-text-white sm:ud-text-4xl md:ud-text-[50px] md:ud-leading-[60px]'>
              {blog.title}
            </h2>
            <div className='ud-flex ud-w-full ud-flex-row items-center justify-center text-center text-white ud-text-dark dark:ud-text-white ud-text-xs sm:ud-text-xs md:ud-text-base'>
              <FontAwesomeIcon icon={faUserAlt} className='' />
              <p className='ud-pl-2'>By {blog.author}</p>
              <p className='ud-pl-4 ud-pr-4  ud-font-bold'>|</p>
              <FontAwesomeIcon icon={faCalendarAlt} className='' />
              <p className='ud-pl-2'>{blog.date}</p>
            </div>
            <div className='ud-flex items-center justify-center'>
              <hr className='h-px my-8 bg-gray-200 border-0 dark:bg-gray-700 w-2/4'></hr>
            </div>
            <div className='ud-flex items-center justify-center'>
              <img
                alt={blog.title}
                src={blog.imageUrl}
                className='ud-w-full'
                style={{ maxWidth: 992 }}
              />
            </div>
            <div className='blogs ud-text-dark dark:ud-text-white ud-mt-8'>
              <div
                className='blogs-content'
                dangerouslySetInnerHTML={{ __html: blog.content }}
              />
            </div>
          </div>
        </section>

        {/* ===== Footer Start ===== */}
        <Footer/>
        {/* ===== Footer End  ===== */}
      </div>
    </Fragment>
  );
};

export default BlogView;
