import { LogoText } from '../../assets/images';

const Footer = () => {
  return (
    <footer
      className='wow fadeInUp mt-10 ud-bg-dark ud-bg-opacity-5 ud-bg-cover ud-pt-14 dark:ud-bg-[#1D232D]'
      data-wow-delay='.2s'
    >
      <div className='ud-px-4 xl:ud-container'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 ud-mx-4 text-left'>
          <div className='ud-w-full'>
            <div className=''>
              <a href='/' className='ud-mb-6 ud-inline-block'>
                <img
                  src={LogoText}
                  alt='logo'
                  className='ud-footer-image ud-hidden ud-h-[50px] dark:ud-block'
                />
                <img
                  src={LogoText}
                  alt='logo'
                  className='ud-footer-image ud-h-[50px] dark:ud-hidden'
                />
              </a>
              <p className='ud-mb-4 ud-text-base ud-text-dark-text'>
                Phrasecode is software engineering company that helps people to
                be success. So if you have idea and looking for execute it,
                don't be hesitate to contact us. We will help you to become your
                dream true.
              </p>
              <div className='ud-ml-4 ud-flex ud-items-center ud-space-x-5'>
                <a
                  href='https://www.facebook.com/info.phrasecode'
                  target='_blank'
                  rel="noreferrer"
                  name='social-link'
                  aria-label='social-link'
                  className='ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    className='ud-fill-current'
                  >
                    <path d='M14 13.5H16.5L17.5 9.5H14V7.5C14 6.47 14 5.5 16 5.5H17.5V2.14C17.174 2.097 15.943 2 14.643 2C11.928 2 10 3.657 10 6.7V9.5H7V13.5H10V22H14V13.5Z' />
                  </svg>
                </a>
                <a
                  href='https://twitter.com/infophrasecode'
                  target='_blank'
                  rel="noreferrer"
                  name='social-link'
                  aria-label='social-link'
                  className='ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    className='ud-fill-current'
                  >
                    <path d='M22.1621 5.65593C21.3986 5.99362 20.589 6.2154 19.7601 6.31393C20.6338 5.79136 21.2878 4.96894 21.6001 3.99993C20.7801 4.48793 19.8811 4.82993 18.9441 5.01493C18.3147 4.34151 17.4804 3.89489 16.571 3.74451C15.6616 3.59413 14.728 3.74842 13.9153 4.18338C13.1026 4.61834 12.4564 5.30961 12.0772 6.14972C11.6979 6.98983 11.6068 7.93171 11.8181 8.82893C10.1552 8.74558 8.52838 8.31345 7.04334 7.56059C5.55829 6.80773 4.24818 5.75098 3.19805 4.45893C2.82634 5.09738 2.63101 5.82315 2.63205 6.56193C2.63205 8.01193 3.37005 9.29293 4.49205 10.0429C3.82806 10.022 3.17868 9.84271 2.59805 9.51993V9.57193C2.59825 10.5376 2.93242 11.4735 3.5439 12.221C4.15538 12.9684 5.00653 13.4814 5.95305 13.6729C5.33667 13.84 4.69036 13.8646 4.06305 13.7449C4.32992 14.5762 4.85006 15.3031 5.55064 15.824C6.25123 16.345 7.09718 16.6337 7.97005 16.6499C7.10253 17.3313 6.10923 17.8349 5.04693 18.1321C3.98464 18.4293 2.87418 18.5142 1.77905 18.3819C3.69075 19.6114 5.91615 20.2641 8.18905 20.2619C15.8821 20.2619 20.0891 13.8889 20.0891 8.36193C20.0891 8.18193 20.0841 7.99993 20.0761 7.82193C20.8949 7.2301 21.6017 6.49695 22.1631 5.65693L22.1621 5.65593Z' />
                  </svg>
                </a>
                <a
                  href='https://www.linkedin.com/company/phrasecode/'
                  target='_blank'
                  rel="noreferrer"
                  name='social-link'
                  aria-label='social-link'
                  className='ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                >
                  <svg
                    width={24}
                    height={24}
                    viewBox='0 0 24 24'
                    className='ud-fill-current'
                  >
                    <path d='M6.93994 5.00002C6.93968 5.53046 6.72871 6.03906 6.35345 6.41394C5.97819 6.78883 5.46938 6.99929 4.93894 6.99902C4.40851 6.99876 3.89991 6.78779 3.52502 6.41253C3.15014 6.03727 2.93968 5.52846 2.93994 4.99802C2.94021 4.46759 3.15117 3.95899 3.52644 3.5841C3.9017 3.20922 4.41051 2.99876 4.94094 2.99902C5.47137 2.99929 5.97998 3.21026 6.35486 3.58552C6.72975 3.96078 6.94021 4.46959 6.93994 5.00002ZM6.99994 8.48002H2.99994V21H6.99994V8.48002ZM13.3199 8.48002H9.33994V21H13.2799V14.43C13.2799 10.77 18.0499 10.43 18.0499 14.43V21H21.9999V13.07C21.9999 6.90002 14.9399 7.13002 13.2799 10.16L13.3199 8.48002Z' />
                  </svg>
                </a>
              </div>
              <p className='mt-4 ud-mb-10 ud-font-light ud-text-dark-text'>
                Copyright © phrasecode.com. All rights reserved.
              </p>
            </div>
          </div>
          <div className='ud-w-full'>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4'>
              <div className='ud-mb-10'>
                <h3 className='ud-mb-9 ud-font-heading ud-text-2xl ud-font-medium ud-text-dark dark:ud-text-white'>
                  Company
                </h3>
                <ul className='ml-4 ud-space-y-3'>
                  <li>
                    <a
                      href='/'
                      className='ud-font-heading ud-text-base ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href='/#features'
                      className='ud-font-heading ud-text-base ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                    >
                      Services
                    </a>
                  </li>
                  <li>
                    <a
                      href='/portfolio'
                      className='ud-font-heading ud-text-base ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                    >
                      Portfolio
                    </a>
                  </li>
                  <li>
                    <a
                      href='/#about'
                      className='ud-font-heading ud-text-base ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href='/#support'
                      className='ud-font-heading ud-text-base ud-text-dark-text hover:ud-text-primary dark:hover:ud-text-white'
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className='ud-mb-10'>
                <h3 className='ud-mb-9 ud-font-heading ud-text-2xl ud-font-medium ud-text-dark dark:ud-text-white'>
                  Looking More
                </h3>
                <div className='ml-4 ud-space-y-2'>
                  <p className='ud-font-bold ud-text-dark-text'>Headoffice:</p>
                  <p className='ud-font-thin ud-text-dark-text'>
                    Negombo, Sri Lanka
                  </p>
                  <br />
                  <p className='ud-font-bold ud-text-dark-text'>
                    <a href='tel:+94741460800'>Hotline : +94 74 146 0800 </a>
                  </p>
                  <p className='ud-font-bold ud-text-dark-text ud-inline-block'>
                    <a href='mailto:hello@phrasecode.com'>
                      Email : hello@phrasecode.com
                    </a>
                  </p>
                  <div className='mt-6 ud-font-thin ud-text-dark-text'>
                    <p>
                      <a href='/disclaimer'>Disclaimer</a> |
                      <a href='/privacy-policy'> Privacy Policy</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
