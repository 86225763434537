import React, { memo } from 'react';

const Tooltip = memo((props) => {
  return (
    <span className='group relative' >
      <span style={{background:'#7d7d7d',zIndex:100, height:"auto", transitionDuration:"1s",transitionDelay:"1s",}} className="pointer-events-none absolute -top-10 whitespace-nowrap rounded px-2 py-1 text-white ud-text-sm opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-black before:content-[''] group-hover:opacity-100">
        {props.text}
      </span>

      {props.children}
    </span>
  );
});

Tooltip.displayName = 'Tooltip';

export { Tooltip };
