import { Header } from '../../components/header';
import { Fragment } from 'react';
import { Footer } from '../../components/footer';
import { SEODecorator } from '../../shared/SEODecorator';

const Disclaimer = () => {
  return (
    <Fragment>
      <SEODecorator
        title={'Phrasecode | Disclaimer | Remote Software Engineering Service'}
        description={
          'Disclaimer: The information provided on our website is for general informational purposes only. As a leading software company, Phrasecode provides cutting-edge technologies and quality services, including AI, machine learning, blockchain solutions, and software development.'
        }
        imageUrl={'https://phrasecode.com/public/Innovation-amico.png'}
        imageAlt={'Phrasecode | Remote Software Engineering Service'}
        type={'website'}
      />
      <div className=''>
        <Header isDarkModeOff={true} />
        <section className='ud-mt-36'>
          <div class='container text-justify ud-mx-auto ud-px-14 ud-font-light dark:ud-text-white lg:ud-px-20 xl:ud-px-20'>
            <h1 class='ud-font-heading ud-text-3xl ud-font-bold'>Disclaimer</h1>
            <br />
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              Disclaimer for Phrasecode:
            </h2>
            <br />
            <p>
              If you are looking for any more information or have any questions
              about phrasecode.com&rsquo;s disclaimer, please feel free to
              contact us by email at
              <a href='mailto:hello@phrasecode.com'>hello@phrasecode.com</a>
            </p>
            <br />
            <p>
              All the information on this website is published in good faith and
              for general information purposes only.
              <a href='https://phrasecode.com/'>https://phrasecode.com/</a> does
              not make any warranties about the completeness, reliability, and
              accuracy of this information. Any action you take the information
              you find on this website ( https://phrasecode.com/ ), is strictly
              at your own risk. phrasecode.com will not be liable for any losses
              and/or damages in connection with the use of our website.
            </p>
            <br />
            <p>
              From our website, you can visit other websites by following
              hyperlinks to such external sites. While we strive to provide only
              quality links to useful and ethical websites, we have no control
              over the content and nature of these sites. These links to other
              websites do not imply a recommendation for all the content found
              on these sites. Site owners and content may change without notice
              and may occur before we have the opportunity to remove a link that
              may have gone &lsquo;bad&rsquo;.
            </p>
            <br />
            <p>
              Please be also aware that when you leave our website, other sites
              may have different privacy policies and terms that are beyond our
              control. Please be sure to check the Privacy Policies of these
              sites as well as their &ldquo;Terms of Service&rdquo; before
              engaging in any business or uploading any information.
            </p>
            <br />
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              Consent:
            </h2>
            <br />
            <p>
              By using our website, you hereby consent to our disclaimer and
              agree to its terms.
            </p>
            <br />
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>Update:</h2>
            <br />
            <p>
              This site disclaimer was last updated on 20th July 2022. If we
              update, amend or make any changes to this document, those changes
              will be prominently posted here.
            </p>
            <p>
              <br />
            </p>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Disclaimer;
