import { Header } from '../../components/header';
import { BlogList } from '../../components/blogList';
import { useEffect, useState, Fragment } from 'react';
import { getBlogs } from '../../services';
import { Footer } from '../../components/footer';
import { SEODecorator } from '../../shared/SEODecorator';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const data = await getBlogs(16);
      setBlogs(data);
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      <SEODecorator
        title={'Phrasecode | Explore Our Latest Blogs on Cutting-Edge Technologies'}
        description={
          'Discover the latest industry trends and insights with our informative blogs! As a leading software company, Phrasecode provides cutting-edge technologies and quality services, including AI, machine learning, blockchain solutions, and software development.'
        }
        imageUrl={'https://phrasecode.com/public/Innovation-amico.png'}
        imageAlt={'Phrasecode | Remote Software Engineering Service'}
        type={'website'}
      />
      <div className=''>
        <Header />

        <section className='ud-pt-24'>
          <div className='ud-px-4 2xl:ud-container'>
            <div
              className='wow fadeInUp ud-relative ud-mx-auto ud-mb-12 ud-max-w-[620px] ud-pt-6 ud-text-center lg:ud-mb-20 lg:ud-pt-16'
              data-wow-delay='.2s'
            >
              <span className='title'> BLOGS </span>
              <h2 className='ud-mb-5 ud-font-heading ud-text-3xl ud-font-semibold ud-text-dark dark:ud-text-white sm:ud-text-4xl md:ud-text-[50px] md:ud-leading-[60px]'>
                Get to Know About Phrasecode
              </h2>
              <p className='ud-text-base ud-text-dark-text'>
                Phrasecode's pride is delivering top-notch, cutting-edge
                solutions to it's clients with sophisticated, reliable and
                easy-to-use solutions.
              </p>
            </div>
            <div
              className='wow fadeInUp ud-relative ud-z-10 ud-overflow-hidden ud-rounded ud-px-8 ud-pt-0 ud-pb-8 md:ud-px-[70px] md:ud-pb-[70px] lg:ud-px-[60px] lg:ud-pb-[60px] xl:ud-px-[70px] xl:ud-pb-[70px]'
              data-wow-delay='.3s'
            >
              <div className='ud-w-full'>
                <BlogList blogs={blogs} />
              </div>
            </div>
          </div>
        </section>

        <Footer/>
      </div>
    </Fragment>
  );
};

export default Blogs;
