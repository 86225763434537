import { Fragment } from "react";
import { SEODecorator } from "../../shared/SEODecorator";

const NotFound = () => {
  return (
    <Fragment>
      <SEODecorator
        title={'404 - Page Not Found'}
        description={ 'Oops! Looks like you\'ve landed on a page that doesn\'t exist. But don\'t worry, you\'re still in the right place for cutting-edge technologies and quality services to help your startup succeed.'
        }
        imageUrl={'https://phrasecode.com/public/Innovation-amico.png'}
        imageAlt={'Phrasecode | Remote Software Engineering Service'}
        type={'website'}
      />
      <div className='flex items-center justify-center ud-h-full p-16' >
      <div className='flex flex-col items-center justify-center px-5 mx-auto my-8 text-white ud-h-full'>
        <div className='max-w-md text-center justify-center ud-h-full ud-mt-32'>
          <h2 className='mb-8 font-extrabold text-9xl'>
            404
          </h2>
          <p className='text-2xl font-semibold md:text-3xl'>
            Sorry, we couldn't find this page.
          </p>
          <p className='mt-4 mb-8'>
            But dont worry, you can find plenty of other things on our homepage.
          </p>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default NotFound;
