import { Helmet } from 'react-helmet';

const SEODecorator = ({
  title,
  description,
  imageUrl,
  imageAlt,
  type,
  author,
  keywords,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='author' content={author || 'Phrasecode Pvt Ltd '} />
      <meta
        name='keywords'
        content={
          keywords ||
          'Software Development, Digital Transformation, Managed Services, Co Development, AWS Development, Serverless Development'
        }
      />

      <meta property='og:type' content={type} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={imageUrl} />
      <meta
        property='og:url'
        content={
          window.location.host +
          window.location.pathname +
          window.location.search
        }
      />

      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:image' content={imageUrl} />
      <meta property='twitter:image:alt' content={imageAlt} />
    </Helmet>
  );
};

export { SEODecorator };
