import { Config } from '../config';
import axios from 'axios';

const ApiClient = axios.create({
  baseURL: Config.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 3000
});


export {ApiClient};
