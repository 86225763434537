import { BlogList } from '../../components/blogList';
import { useEffect, useState } from 'react';
import { getBlogs } from '../../services';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getBlogs(8);
      setBlogs(data);
    }
    fetchData();
  }, []);

  return (
    <div>
      <div className='ud-px-4 2xl:ud-container'>
        {/* Section Title */}
        <div
          className='wow fadeInUp ud-relative ud-mx-auto ud-mb-12 ud-max-w-[620px] ud-pt-6 ud-text-center lg:ud-mb-20 lg:ud-pt-16'
          data-wow-delay='.2s'
        >
          <span className='title'> BLOGS </span>
          <h2 className='ud-mb-5 ud-font-heading ud-text-3xl ud-font-semibold ud-text-dark dark:ud-text-white sm:ud-text-4xl md:ud-text-[50px] md:ud-leading-[60px]'>
            Latest News & Articles From Our Blog
          </h2>
          <p className='ud-text-base ud-text-dark-text'>
            Phrasecode's pride is delivering top-notch, cutting-edge solutions
            to it's clients with sophisticated, reliable and easy-to-use
            solutions.
          </p>
        </div>
        <div
          className='wow fadeInUp ud-relative ud-z-10 ud-overflow-hidden ud-rounded ud-px-8 ud-pt-0 ud-pb-8 md:ud-px-[70px] md:ud-pb-[70px] lg:ud-px-[60px] lg:ud-pb-[60px] xl:ud-px-[70px] xl:ud-pb-[70px] background-gradient'
          data-wow-delay='.3s'
        >
          <div className='ud-absolute ud-top-0 ud-left-0 ud--z-10 ud-h-full ud-w-full ud-bg-cover ud-bg-center ud-opacity-40 dark:ud-bg-[#1D232D]' />
          <div className='ud-absolute ud-bottom-0 ud-left-1/2 ud--z-10 ud--translate-x-1/2'>
            <svg
              width={1174}
              height={560}
              viewBox='0 0 1174 560'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g opacity='0.4' filter='url(#filter0_f_41_257)'>
                <rect
                  x='450.531'
                  y={279}
                  width='272.933'
                  height='328.051'
                  fill='url(#paint0_linear_41_257)'
                />
              </g>
              <defs>
                <filter
                  id='filter0_f_41_257'
                  x='0.531494'
                  y={-171}
                  width='1172.93'
                  height='1228.05'
                  filterUnits='userSpaceOnUse'
                  colorInterpolationFilters='sRGB'
                >
                  <feFlood floodOpacity={0} result='BackgroundImageFix' />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='BackgroundImageFix'
                    result='shape'
                  />
                  <feGaussianBlur
                    stdDeviation={225}
                    result='effect1_foregroundBlur_41_257'
                  />
                </filter>
                <linearGradient
                  id='paint0_linear_41_257'
                  x1='425.16'
                  y1='343.693'
                  x2='568.181'
                  y2='660.639'
                  gradientUnits='userSpaceOnUse'
                >
                  <stop stopColor='#ABBCFF' />
                  <stop offset='0.859375' stopColor='#4A6CF7' />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className='ud-w-full'>
            <BlogList blogs={blogs} />
          </div>
          <div
            className='wow fadeInUp ud-flex ud-flex-wrap ud-items-center ud-justify-center md:ud-justify-center'
            data-wow-delay='.5s'
          >
            <a
              href='./blogs'
              className='ud-inline-flex ud-items-center ud-rounded ud-bg-primary ud-py-[10px] ud-px-6 ud-font-heading ud-text-base ud-text-white hover:ud-bg-opacity-90 md:ud-py-[14px] md:ud-px-8'
            >
              Read More
              <span className='ud-pl-3'>
                <svg
                  width={16}
                  height={16}
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M12.172 7L6.808 1.636L8.222 0.222L16 8L8.222 15.778L6.808 14.364L12.172 9H0V7H12.172Z'
                    fill='white'
                  />
                </svg>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Blogs };
