import LogoIcon from './logo.png'
import Logo from './phrasecode-logo.png'
import InnovationAmico from './Innovation-amico.png'
import PlugseekDark from './plugseek-dark.png'
import PlugseekWhite from './plugseek-white.png'
import Consulterdoor from './consulter.png'
import About1 from './about-1.png'
import Programmer from './Programmer-bro.png'
import About3 from './about-3.png'
import Nawinne from './nawinne-plain-2.png'

const LogoText = 'https://phrasecode.com/public/phrasecode-logo-text.png'


export {
    Logo,
    LogoIcon,
    LogoText,
    InnovationAmico,
    PlugseekDark,
    PlugseekWhite,
    Consulterdoor,
    About1,
    Programmer,
    About3,
    Nawinne
}