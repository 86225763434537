import { Header } from '../../components/header';
import { Fragment } from 'react';
import { Footer } from '../../components/footer';
import { SEODecorator } from '../../shared/SEODecorator';

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <SEODecorator
        title={
          'Phrasecode | Privacy Policy | Remote Software Engineering Service'
        }
        description={
          'At Phrasecode, we are committed to protecting your privacy. We are a leading software company providing cutting-edge technologies and quality services, including AI, machine learning, blockchain solutions, and software development.'
        }
        imageUrl={'https://phrasecode.com/public/Innovation-amico.png'}
        imageAlt={'Phrasecode | Remote Software Engineering Service'}
        type={'website'}
      />
      <div className=''>
        <Header isDarkModeOff={true} />

        <section className='ud-mt-36'>
          <div class='container text-justify ud-mx-auto ud-px-14 ud-font-light dark:ud-text-white lg:ud-px-20 xl:ud-px-20'>
            <h1 class='ud-font-heading ud-text-3xl ud-font-bold'>
              Privacy Policy
            </h1>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              Who we are
            </h2>
            <p>
              Suggested text:&nbsp;Our website address is:&nbsp;
              <a href='https://phrasecode.com/.'>https://phrasecode.com/.</a>
            </p>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              Comments
            </h2>
            <p>
              Suggested text:&nbsp;When visitors leave comments on the site we
              collect the data shown in the comments form, and also the
              visitor&rsquo;s IP address and browser user agent string to help
              spam detection.
            </p>
            <p>
              <br />
            </p>
            <p>
              An anonymized string created from your email address (also called
              a hash) may be provided to the Gravatar service to see if you are
              using it. The Gravatar service privacy policy is available here:
              https://phrasecode.com/privacy-policy/. After approval of your
              comment, your profile picture is visible to the public in the
              context of your comment.
            </p>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>Media</h2>
            <p>
              Suggested text:&nbsp;If you upload images to the website, you
              should avoid uploading images with embedded location data (EXIF
              GPS) included. Visitors to the website can download and extract
              any location data from images on the website.
            </p>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>Cookies</h2>
            <p>
              Suggested text:&nbsp;If you leave a comment on our site you may
              opt-in to saving your name, email address, and website in cookies.
              These are for your convenience so that you do not have to fill in
              your details again when you leave another comment. These cookies
              will last for one year.
            </p>
            <p>
              <br />
            </p>
            <p>
              If you visit our login page, we will set a temporary cookie to
              determine if your browser accepts cookies. This cookie contains no
              personal data and is discarded when you close your browser.
            </p>
            <p>
              <br />
            </p>
            <p>
              When you log in, we will also set up several cookies to save your
              login information and your screen display choices. Login cookies
              last for two days, and screen options cookies last for a year. If
              you select &ldquo;Remember Me&rdquo;, your login will persist for
              two weeks. If you log out of your account, the login cookies will
              be removed.
            </p>
            <p>
              <br />
            </p>
            <p>
              If you edit or publish an article, an additional cookie will be
              saved in your browser. This cookie includes no personal data and
              simply indicates the post ID of the article you just edited. It
              expires after 1 day.
            </p>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              Embedded content from other websites
            </h2>
            <p>
              Suggested text:&nbsp;Articles on this site may include embedded
              content (e.g. videos, images, articles, etc.). Embedded content
              from other websites behaves in the exact same way as if the
              visitor has visited the other website.
            </p>
            <p>
              <br />
            </p>
            <p>
              These websites may collect data about you, use cookies, embed
              additional third-party tracking, and monitor your interaction with
              that embedded content, including tracking your interaction with
              the embedded content if you have an account and are logged in to
              that website.
            </p>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              Who do we share your data with
            </h2>
            <p>
              Suggested text:&nbsp;If you request a password reset, your IP
              address will be included in the reset email.
            </p>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              How long we retain your data
            </h2>
            <p>
              Suggested text:&nbsp;If you leave a comment, the comment and its
              metadata are retained indefinitely. This is so we can recognize
              and approve any follow-up comments automatically instead of
              holding them in a moderation queue.
            </p>
            <p>
              <br />
            </p>
            <p>
              For users that register on our website (if any), we also store the
              personal information they provide in their user profile. All users
              can see, edit, or delete their personal information at any time
              (except they cannot change their username). Website administrators
              can also see and edit that information.
            </p>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              What rights do you have over your data
            </h2>
            <p>
              Suggested text:&nbsp;If you have an account on this site, or have
              left comments, you can request to receive an exported file of the
              personal data we hold about you, including any data you have
              provided to us. You can also request that we erase any personal
              data we hold about you. This does not include any data we are
              obliged to keep for administrative, legal, or security purposes.
            </p>
            <p>
              <br />
            </p>
            <h2 class='ud-font-heading ud-text-xl ud-font-semibold'>
              Where do we send your data
            </h2>
            <p>
              Suggested text:&nbsp;Visitor comments may be checked through an
              automated spam detection service.
            </p>
            <p>
              <br />
            </p>
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default PrivacyPolicy;
